<script setup lang="ts">
import { useLogic } from "./logic";
import { useRoute } from "vue-router";
import { useAppStore } from "./store/app";
import { useTenantStore } from "./store/tenant";
import { useSystemStore } from "./store/system";
import { useChannelStore } from "./store/channel";
import { useElementStore } from "@/store/element";
import { jsBridgeInit } from "./utils/app";
import { onBeforeMount, onMounted, watch, onBeforeUnmount, computed } from "vue";
import { IonApp, IonRouterOutlet, IonContent, IonSpinner, IonImg } from "@ionic/vue";
import CopyUrlModal from "@/components/CopyUrlModal.vue";
import TenantModal from "@/components/TenantsModal.vue";

const route = useRoute(); // 当前路由
const appStore = useAppStore(); // 用户信息
const systemStore = useSystemStore(); // 系统信息
const tenantStore = useTenantStore(); // 租户信息
const channelStore = useChannelStore(); // 渠道信息
const elementStore = useElementStore(); // 元素信息

const isIOS = computed(() => systemStore.isIOS); // 是否是IOS
const maxWidth = computed(() => elementStore.screenWidth); // 最大宽度
const appLogo = computed(() => tenantStore.tenantInfo?.logo); // 加载LOGO
const copyUrlModalVisible = computed(() => channelStore.copyUrlModalVisible); // 拷贝URL模态框是否显示

const { isLoaded, tenants, tenantsModalVisible, webAppInit, selectTenantHandle, appInit, removeListener } = useLogic(route); // 逻辑处理


// 监听商户信息变化
watch(
  [() => tenantStore.tenantId, () => appStore.token],
  async ([id, _token]) => {
    if (id) {
      webAppInit();
    }
  },
  { immediate: true },
);
watch(
  () => route.path,
  (val) => {
    const floatBtn = document.querySelector(".xuanfu") as HTMLElement;
    if (/^\/game/.test(val)) {
      floatBtn.style.display = "none";
    } else {
      floatBtn.style.display = "block";
    }
  },
);
/**
 * 生命周期-页面加载前
 */
onBeforeMount(() => {
  jsBridgeInit(); // 初始化原生交互方法
  tenantStore.loadTenantInfo(); // 加载商户信息
});

/**
 * 生命周期-页面加载前
 */
onMounted(async () => {
  appInit();
  if (window.isSamsungBrowser()) {
    setTimeout(() => {
      const script = document.createElement("script");
      script.id = "web-app-script-webPushSdk";
      script.src = "/webPushSdk.produce.min.2.1.6.js";
      document.head.appendChild(script);
    }, 3000);
  }
});

/**
 * @description 拷贝URL模态框关闭事件
 */
function copyUrlModalDismiss() {
  channelStore.copyUrlModalVisible = false;
}

/**
 * 生命周期-页面卸载前
 */
onBeforeUnmount(() => {
  removeListener();
});
</script>

<template>
  <ion-app>
    <ion-content v-if="isLoaded" id="app-content" :scroll-y="false">
      <ion-router-outlet class="mx-auto overflow-hidden" :style="`max-width:${maxWidth}px`" :animated="!isIOS" />
      <!-- PWA操作按钮 -->
      <button class="hidden progressier-install-button" data-icons="false" :data-install="$t('viewsSystem.install')"
        :data-installed="$t('label.launchApp')" />
    </ion-content>
    <ion-content v-else>
      <div class="flex flex-col items-center justify-center h-full">
        <ion-img class="min-w-[9.375rem] max-w-[17.1875rem] mb-10" :src="appLogo"
          v-if="appLogo && !route.path.includes('/download')" />
        <ion-spinner class="w-10 h-10" name="crescent" />
      </div>
    </ion-content>
    <!-- 选择商户弹窗 -->
    <TenantModal :tenants="tenants" :visible="tenantsModalVisible" @tenantChange="selectTenantHandle" />
    <!-- 拷贝URL弹窗 -->
    <CopyUrlModal :visible="copyUrlModalVisible" @modalDismiss="copyUrlModalDismiss" />
  </ion-app>
</template>

<style>
@import "swiper/css";
/* 轮播图样式 */
@import "tailwindcss/base";
/* tailwindcss样式库 */
@import "@/common/global.less";
/* 全局样式 */
@import "@/common/record.css";
/* 全局样式 */
@import "swiper/css/pagination";
/* 轮播图指示器样式 */
@import "tailwindcss/utilities";
/* tailwindcss样式库 */
@import "tailwindcss/components";
/* tailwindcss样式库 */

ion-app {
  background: var(--color-screen-bg);
}

#app-content {
  --background: transparent;
  background-image: var(--color-screen-bg-img);
  background-size: 5rem;
  /* 设置背景图像块的大小为100px x 100px */
  background-repeat: repeat;
  /* 设置背景图像平铺 */
}

@media all and (display-mode: standalone),
all and (display-mode: fullscreen),
all and (display-mode: minimal-ui),
all and (display-mode: widows-controls-overlay) {

  #pwa-bar,
  #pwa-footer-modal,
  #app-install-bar {
    display: none;
  }
}
</style>
